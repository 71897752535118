<template>
  <div class="container-pc">
    <div class="login-view Global-W">
      <!-- <div class="login-title"></div> -->
      <!-- <div class="login-h"></div> -->

      <div class="login-box">
        <div class="login-left">
          <div class="login-right-title FROM">
            Register Account
          </div>
          <div class="login-right-dec">
            You can keep track of your online orders and get access to exclusive services through your account

            <div class="login-Create CURSOR" @click="handleGoPage">Create a member account</div>

          </div>
        </div>
        <div class="login-jiange"></div>
        <div class="login-right">
          <div class="login-right-title FROM">
            Log in to your ROSEONLY account
          </div>

          <div class="login-right-state">
            <div :class="[loginType == 'MAIL' ? 'login-right-state-select' : '', 'CURSOR f-w']"
              @click="handleChangeLogin('MAIL')">Email Login</div>
            <!-- <div :class="[loginType == 'MOBILE' ? 'login-right-state-select' : '', 'CURSOR f-w']"
              @click="handleChangeLogin('MOBILE')">Phone number login</div> -->
          </div>

          <!-- Email -->

          <div class="login-input-item" v-if="loginType == 'MAIL'">
            <div>{{ $t('Login.Email') }}</div>
            <el-input v-model="loginData.phone" class="login-input" clearable>
            </el-input>
          </div>

          <!-- Phone -->
          <div class="login-input-item" v-if="loginType == 'MOBILE'">
            <div>Country or region</div>
            <el-select v-model="loginData.prefix" placeholder="Country or region" class="login-input" size="large">
              <el-option v-for="item in countryList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="login-input-item" v-if="loginType == 'MOBILE'">
            <div>{{ $t('Login.Email') }}</div>
            <el-input v-model="loginData.phone" class="login-input" clearable>
              <template #prepend>+{{ loginData.prefix }}</template>
            </el-input>
          </div>
          <!--  -->

          <div class="login-input-item">
            <div>{{ $t('Login.Password') }}</div>
            <el-input v-model="loginData.pwd" class="login-input" show-password clearable />

          </div>
          <span class="login-Forgot CURSOR" @click="handleGoFind">Forgot your password?</span>
          <el-button class="login-sign" @click="handleLogin">Login</el-button>
          <div class="login-vip">
            By signing in, you agree to the Terms of Use and acknowledge the
            Privacy Policy. California consumers, see our Notice of Financial
            Incentives.
          </div>


        </div>
      </div>
    </div>
  </div>

  <div class="container-m">
    <div class="login-m-view">
      <div class="login-m-title f-w">Log in to your ROSEONLY account</div>

      <div class="login-m-state">
        <div :class="[loginType == 'MAIL' ? 'login-right-state-select' : '', 'CURSOR f-w']"
          @click="handleChangeLogin('MAIL')">Email Login</div>
        <!-- <div :class="[loginType == 'MOBILE' ? 'login-right-state-select' : '', 'CURSOR f-w']"
          @click="handleChangeLogin('MOBILE')">Phone number login</div> -->
      </div>

      <div class="login-m-input-item" v-if="loginType == 'MAIL'">
        <div class="login-label">Email</div>
        <input type="text" class="login-value" placeholder="Email" v-model="loginData.phone">
      </div>

      <div class="login-m-input-item" v-if="loginType == 'MOBILE'">
        <div class="login-label"></div>
        <input type="text" class="login-value" placeholder="Country or region" @click="handleShowCountry"
          v-model="country.label">
      </div>

      <div class="login-m-input-item" v-if="loginType == 'MOBILE'">
        <div class="login-label">+{{ loginData.prefix }}</div>
        <input type="text" class="login-value" placeholder="Phone number" v-model="loginData.phone">
      </div>

      <div class="login-m-input-item">
        <div class="login-label">password</div>
        <input type="password" class="login-value" placeholder="Password" v-model="loginData.pwd">
      </div>

      <div class="login-Forgot CURSOR">Forgot your password?</div>

      <van-button class="login-button" block type="primary" color="#414141" native-type="submit"
        @click="handleLogin">Login</van-button>
    </div>

    <div class="login-m-register">

      <div class="login-m-title f-w">Register Account</div>
      <div class="login-m-tips f-w">
        You can keep track of your online orders and get access to exclusive services through your account
      </div>
      <div class="login-Create CURSOR" @click="handleGoPage">Create a member account</div>
    </div>
  </div>

  <van-popup v-model:show="isShowCountry" round position="bottom" :style="{ height: '50%' }">
    <van-picker title="Country or region" :columns="countryList" @confirm="handleOnCountryConfirm"
      @cancel="handleShowCountry" :columns-field-names="{ text: 'label' }" confirm-button-text="Confirm"
      cancel-button-text="Cancel" />
  </van-popup>

</template>

<script setup name="Login">
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import requestAsync from "../../utils/request";
import { ElMessage } from 'element-plus'
import Cookies from 'js-cookie'
import { useStore } from "vuex";
import CountlyEvent from "@/utils/countlyEvent";
const countly = new CountlyEvent()
const store = useStore()
const route = useRoute()
const router = useRouter()
const loginData = ref({
  prefix: '86',
  email: '',
  phone: '',
  pwd: ''
})

const isShowCountry = ref(false)
const country = ref({ value: '86', label: '中国' })

const countryList = [
  { value: '61', label: '澳大利亚' },
  { value: '82', label: '韩国' },
  { value: '1', label: '加拿大' },
  { value: '60', label: '马来西亚' },
  { value: '1', label: '美国' },
  { value: '81', label: '日本' },
  { value: '65', label: '新加坡' },
  { value: '44', label: '英国' },
  { value: '86', label: '中国' },
  { value: '852', label: '中国香港' },
  { value: '853', label: '中国澳门' },
  { value: '886', label: '中国台湾' },
  { value: '33', label: '法国' },
  { value: '49', label: '德国' },
  { value: '39', label: '意大利' },
  { value: '3', label: '俄罗斯' },
]

let isLogin = ref(true)
let loginType = ref('MAIL') // MAIL MOBILE

const handleGoFind = () => {
  router.push({
    name: 'FindPwd'
  })
}

const handleLogin = async () => {
  if (!loginData.value.phone) {
    return ElMessage.error('Please enter your email address!')
  }
  if (!loginData.value.pwd) {
    return ElMessage.error('Please enter password!')
  }
  const Login = await requestAsync({
    url: "/user/login",
    data: {
      prefix: loginData.value.prefix,
      username: loginData.value.phone,
      password: loginData.value.pwd,
      logintag: 2,
      loginBy: loginType.value
    }
  });
  console.log('Login', Login)
  if (Login.code == 200) {
    ElMessage({
      showClose: true,
      message: 'Login ！',
      type: 'success',
    })


    Cookies.set('uid', Login.data.uid)
    Cookies.set('token', Login.data.token)
    localStorage.setItem('token', Login.data.token)
    store.dispatch('login', true)
    countly.saveUserId(Login.data.uid, true)
    if (route.query.redirect) {
      router.push({
        path: route.query.redirect
      })
    } else {
      router.push({
        name: 'Home'
      })
    }
  } else {
    ElMessage.error('Login failure')
  }
}


const handleGoPage = () => {
  router.push({
    name: 'Register'
  })
}

const handleChangeLogin = (type) => {
  loginType.value = type
}

const handleShowCountry = () => {
  isShowCountry.value = !isShowCountry.value
}

const handleOnCountryConfirm = (selectedValues) => {
  loginData.value.prefix = selectedValues.selectedOptions[0].value
  country.value = selectedValues.selectedOptions[0]
  isShowCountry.value = false
}


</script>

<style lang="scss" scoped>
:deep() {
  .el-button {
    background-color: $globalColor;
    color: #fff;
    font-weight: 700;
    border-color: $globalColor;
  }

  .el-button:hover {
    border-color: $globalColor;
  }

  .is-disabled {
    color: #fff;
    background-color: #bfbfbf;
    font-weight: 700;
    border-color: #bfbfbf;
  }

  .is-disabled:hover {
    color: #fff;
    background-color: #bfbfbf;
    font-weight: 700;
    border-color: #bfbfbf;
  }
}


.login-right-state-select {
  color: $SelectColor;
}

.login-title {
  width: 100%;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  color: #000;
  // border-bottom: 1px solid #d5d5d5;
}

.login-h {
  width: 100%;
  height: 1px;
  background-color: #d5d5d5;
  margin: 30px 0;
}

.login-box {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;

  .login-left {
    width: 35%;

    .login-right-dec {
      margin: 20px 0;
      font-size: 15px;
    }
  }

  .login-jiange {
    width: 1px;
    // height: 200px;
    background-color: #d5d5d5;
    margin: 0 100px;
  }

  .login-right-title {
    height: 35px;
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0;
  }

  .login-right {
    width: 35%;



    .login-right-state {
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      margin: 20px 0;


      div {
        height: 100%;
        line-height: 30px;
        margin-right: 20px;
      }
    }

    .login-input-item {
      width: 100%;

      .login-input {
        height: 50px;
        margin: 5px 0;
      }
    }

    .login-Forgot {
      display: inline-block;
      font-size: 13px;
      height: 25px;
      line-height: 25px;
      border-bottom: 1px solid #414141;
      margin: 5px 0;
    }

    .login-Forgot:hover {
      border-bottom: 1px solid $globalColor;
    }

    .login-sign {
      width: 100%;
      height: 50px;
      line-height: 50px;
      margin: 20px 0;
    }

    .login-vip {
      height: 60px;
      border-bottom: 1px solid #d5d5d5;
      font-size: 12px;
    }


  }

  .login-Create {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 20px;
    font-weight: 700;
    margin: 25px 0;
    border-bottom: 1px solid #414141;
  }
}



.container-m {
  .login-m-view {
    width: 80%;
    margin: 0 auto;

    .login-m-title {
      width: 100%;
      height: .5rem;
      line-height: .5rem;
      text-align: center;
      font-size: .3rem;
    }

    .login-m-state {
      display: flex;
      align-items: center;
      width: 100%;
      height: 1rem;
      font-size: .3rem;

      div {
        margin-right: .2rem;
      }
    }

    .login-m-input-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: .8rem;
      margin: .2rem auto;
      border-bottom: 1px solid #f1f1f1;

      .login-label {
        width: 2rem;
        line-height: .8rem;
        text-align: center;
        font-size: .25rem;
      }

      .login-value {
        width: 4rem;
        font-size: .25rem;
        padding-left: .3rem;
        border: none;
      }
    }

    .login-button {
      // margin: .4rem 0;
    }

    .login-Forgot {
      width: 2.6rem;
      font-size: .25rem;
      height: .25rem;
      line-height: .25rem;
      border-bottom: 1px solid #414141;
      margin: .4rem 0;
    }
  }

  .login-m-register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5rem;

    margin: 1rem 0;

    .login-m-title {
      width: 100%;
      height: .6rem;
      line-height: .5rem;
      text-align: center;
      font-size: .3rem;
    }

    .login-m-tips {
      font-size: .25rem;
      margin: .2rem 0;
      padding: 0 .3rem;
    }

    .login-Create {
      display: inline-block;
      height: .25rem;
      line-height: .25rem;
      font-size: .3rem;
      font-weight: 700;
      margin: .25rem 0;
      border-bottom: 1px solid #414141;
    }
  }


}
</style>